<template lang="pug">
  .filters
    .row.align-items-center.m-b-10
      .col-md-6
        .d-flex.align-items-center
          AppAddNewButton.add-new-button.m-r-6(
            @click="openNewUserModal"
          )
            span.m-l-10 {{ $t("account_management.account_list.add_new_user") }}
          AppDropdown.organization-name(
            v-if="isOrganizationFilterShown"
            checkbox
            batch-select
            multiple
            searchable
            allow-empty
            close-on-select
            :loading="organizationsLoading"
            value-key="id"
            title-key="name"
            :placeholder="$t('maestro.select_organization')"
            :value="filters.organizations"
            :items="organizations"
            @select="setOrganizations"
          )
          AppSearch(
            :value="filters.searchValue"
            @update="applySearch"
          )
      .col-md-6.text-right
        AppDownloadButton(v-if="!isOrganizationsAdminList" disabled)
</template>

<script>
  // mixins
  import withModal from "@/mixins/withModal"
  import withOrganizationAdminList from "@/mixins/withOrganizationAdminList"
  import withOtaAdminList from "@/mixins/withOtaAdminList"

  // components
  import UserForm from "./UserForm"

  export default {
    props: {
      filters: Object,
      organizations: Array,
      organizationsLoading: {
        type: Boolean,
        default: true
      }
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppDownloadButton: () => import("@/components/elements/AppButton/WithIcon/Download"),
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew")
    },

    mixins: [withModal, withOtaAdminList, withOrganizationAdminList],

    methods: {
      setShops(shops) {
        this.applyFilters({ shops })
      },

      setRole(role) {
        this.applyFilters({ role })
      },

      setOrganizations(organizations) {
        this.applyFilters({ organizations })
      },

      applySearch(searchValue) {
        this.applyFilters({ searchValue })
      },

      applyFilters(newFilters) {
        this.$emit("change-filters", { ...this.filters, ...newFilters })
      },

      openNewUserModal() {
        this.$openModal({
          component: UserForm,
          title: this.$t("account_management.account_list.create_account")
        })
      }
    },

    computed: {
      isOrganizationFilterShown() {
        return !(this.isOrganizationsAdminList || this.isOtaAdminList)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .filters
    +filter-title

    margin: 15px 0 15px -6px

    .app-select
      padding: 0 6px
    .add-new-button
      margin-left: 6px
</style>
